import axios from "axios";
const queryString = require('query-string');
const controllerRoute = "/api/parsec-user";

export default {
    getDataView(requestData) {
        let queryParams = queryString.stringify(requestData);
        return axios({
            method: "GET",
            url: `${controllerRoute}/get-data-view?${queryParams}`,
            withCredentials: true,
        });
    },
    addUser(data) {
        return axios({
            method: "POST",
            url: `${controllerRoute}/add-user`,
            data: data,
            withCredentials: true,
        });
    },
    removeUser(userId) {
        return axios({
            method: "GET",
            url: `${controllerRoute}/remove-user/${userId}`,
            withCredentials: true,
        });
    }
}