import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
const queryString = require('query-string');
import parsecUserApi from '@/api/common/parsecUserApi';
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";


export default {

    components: {
        CaretTop,
        CaretBottom,
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
    }, 
    extends: baseComponent,

    data() {
        return {
            isCheckAll: false,
            maxUserValue: 0,
            listData: [],
            isLoading: false,
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.ID',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
            },
            dialogInsertUpdateVisible: false,
            dialogInsertUpdateTitle: "",
            insertUpdateModel: {
                id: "",
            }
        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
        })
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        this.getData();
    },

    mounted() {

    },

    methods: {
        getData() {
            this.isLoading = true;
            parsecUserApi.getDataView({
                query: this.pagination.querySearch,
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
            }).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this, 'maxUserValue', res.data.data.maxUserValue);
                        this.$set(this, 'listData', res.data.data.dataUser.data);
                        this.$set(this, 'pagination', {
                            ...this.pagination,
                            status: 1,
                            orderBy: res.data.data.dataUser.pagingItem.orderBy,
                            directionSort: res.data.data.dataUser.pagingItem.directionSort,
                            pageIndex: res.data.data.dataUser.pagingItem.pageIndex,
                            pageSize: res.data.data.dataUser.pagingItem.pageSize,
                            outRowsNumber: res.data.data.dataUser.pagingItem.outRowsNumber,
                        });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    }
                } catch (error) {
                    console.log("getDataView -> error", error);
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log("getDataView -> error", error);
                this.feedStatusLoading(API_CALL_STATUS.FAILED);
                this.isLoading = false;
            });
        },
        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
                case 'add':
                    this.addItem();
                    break;
                case 'delete':
                    this.deleteItem(param.data.id);
                    break;
            }
        },
        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },
        validateInput(input) {
            if (!input || input.length === 0) {
                return `Please input username or email !`;
            } else return true;
        },
        addItem() {
            this.$prompt(`Please input username or e-mail to grant permission to use Parsec`, 'Add user', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputValidator: this.validateInput,
            }).then(({ value }) => {
                this.$set(this, 'isLoading', true);
                parsecUserApi.addUser({ usernameOrEmail: value }).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.getData();
                            this.$message({
                                message: 'Congrats, User has been allowed using Parsec.',
                                type: 'success'
                            });
                        }
                        else {
                            this.$notify({
                                type: 'error',
                                title: 'Error',
                                message: res.data.message
                            });
                        }
                    } catch (error) {
                        console.log("addItem -> error", error);
                    }
                    this.$set(this, 'isLoading', false);
                }).catch(error => {
                    console.log("addItem -> error", error);
                    this.$set(this, 'isLoading', false);
                });
            }).catch(() => {
                this.$set(this, 'isLoading', false);                
            });
        },
        deleteItem(itemId) {
            this.$confirm('This user will permanently denied using Parsec. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$set(this, 'isLoading', true);
                parsecUserApi.removeUser(itemId).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.getData();
                            this.$message({
                                message: 'Congrats, User has been denied using Parsec.',
                                type: 'success'
                            });
                        }
                        else {
                            this.$notify({
                                type: 'error',
                                title: 'Error',
                                message: res.data.message
                            });
                        }
                    } catch (error) {
                        console.log("addItem -> error", error);
                    }

                    this.$set(this, 'isLoading', false);
                }).catch(error => {
                    console.log("addItem -> error", error);
                    this.$set(this, 'isLoading', false);
                });
            }).catch(() => {

                this.$set(this, 'isLoading', false);
            });
        }
    }
}